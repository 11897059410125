document.addEventListener("DOMContentLoaded", function () {
    function lazyLoad() {
        const lazyElements = document.querySelectorAll('.lazy:not([data-lazy-climbed])');

        lazyElements.forEach(element => {
            // Check if the element supports lazy loading
            let targetElement = element;
            if (!(targetElement.tagName === 'IMG' || targetElement.tagName === 'IFRAME' || targetElement.style.backgroundImage)) {
                // Try to find the nearest child element that supports lazy loading
                targetElement = element.querySelector('img, iframe, [style*="background-image"]');
                if (!targetElement) {
                    // Mark the element as not eligible for lazy loading
                    element.setAttribute('data-lazy-climbed', 'unsupported');
                    return;
                }
            }

            // Initialize styles for the appearance animation
            targetElement.style.opacity = '0';
            targetElement.style.transform = 'translateY(20px)';
            targetElement.style.transition = 'opacity 1s ease, transform 1s ease';

            // Save the original src in data attributes and clear it
            if (targetElement.tagName === 'IMG' || targetElement.tagName === 'IFRAME') {
                targetElement.setAttribute('data-src', targetElement.src);
                targetElement.src = '';
            } else if (targetElement.style.backgroundImage && targetElement.style.backgroundImage !== 'none') {
                const bgImageUrl = targetElement.style.backgroundImage.match(/url\(["']?([^"']*)["']?\)/);
                if (bgImageUrl && bgImageUrl[1]) {
                    targetElement.setAttribute('data-src', bgImageUrl[1]);
                    targetElement.style.backgroundImage = '';
                }
            }

            // Mark the element as processed
            targetElement.setAttribute('data-lazy-climbed', 'true');

            // Create and configure the observer
            const observer = new IntersectionObserver(entries => {
                entries.forEach(entry => {
                    if (entry.isIntersecting) {
                        const lazyElement = entry.target;
                        const dataSrc = lazyElement.getAttribute('data-src');
                        if (dataSrc) {
                            if (lazyElement.tagName === 'IMG' || lazyElement.tagName === 'IFRAME') {
                                lazyElement.src = dataSrc;
                            } else {
                                lazyElement.style.backgroundImage = `url(${dataSrc})`;
                            }

                            // Animation after loading
                            lazyElement.onload = () => {
                                lazyElement.style.opacity = '1';
                                lazyElement.style.transform = 'translateY(0)';
                            };

                            if (!lazyElement.tagName.match(/IMG|IFRAME/)) {
                                lazyElement.style.opacity = '1';
                                lazyElement.style.transform = 'translateY(0)';
                            }
                        }

                        // Stop observing the element
                        observer.unobserve(lazyElement);
                    }
                });
            });

            // Start observing each element
            observer.observe(targetElement);
        });
    }

    if ('IntersectionObserver' in window) {
        // Initialize lazy loading
        lazyLoad();
        // Expose the function for external invocation if needed
        window.lazyLoad = lazyLoad;
    } else {
        // Fallback for browsers that do not support IntersectionObserver
        const lazyElements = document.querySelectorAll('.lazy');
        lazyElements.forEach(element => {
            let targetElement = element;
            if (!(targetElement.tagName === 'IMG' || targetElement.tagName === 'IFRAME' || targetElement.style.backgroundImage)) {
                targetElement = element.querySelector('img, iframe, [style*="background-image"]');
                if (!targetElement) {
                    element.setAttribute('data-lazy-climbed', 'unsupported');
                    return;
                }
            }
            const dataSrc = targetElement.getAttribute('data-src');
            if (dataSrc) {
                if (targetElement.tagName === 'IMG' || targetElement.tagName === 'IFRAME') {
                    targetElement.src = dataSrc;
                } else if (targetElement.style.backgroundImage && targetElement.style.backgroundImage !== 'none') {
                    targetElement.style.backgroundImage = `url(${dataSrc})`;
                }
                targetElement.style.opacity = '1';
                targetElement.style.transform = 'translateY(0)';
            }
        });
    }
});
